@use '@angular/material' as mat;

@import 'variables';
@import 'icons';
/* include bootstrap */
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities/sizing';
@import 'bootstrap/scss/utilities/text';
/* include mat.core */
@include mat.core();
/* include mat.core-theme */
@include mat.core-theme($retail-light-theme);
/* include mat.all-component-themes */
@include mat.all-component-themes($retail-light-theme);
/* include mat.all-component-typographies */
@include mat.all-component-typographies();
/* include mat.form-field-density */
@include mat.form-field-density(minimum);

/* include dark-theme */
.retail-dark-theme {
  @include mat.core-color($retail-dark-theme);
  @include mat.all-component-colors($retail-dark-theme);
}

/* include styles */
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #212529;
  background-color: #fafafa;
}

a {
  color: $app-color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $app-color-primary;
    text-decoration: underline;
  }
}

a.mdc-button,
a.card,
a.mat-card,
a.mat-mdc-card {
  text-decoration: none;
}

p {
  margin: 0 0 1rem;
}

img.ng-lazyloaded {
  animation: fadein 0.5s;
}

.text-primary {
  color: $app-color-primary;
}

.text-accent {
  color: $app-color-accent;
}

.text-warn {
  color: $app-color-warn;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1480px;
}

.mat-mdc-card {
  @include mat.elevation(2);
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-datepicker-toggle {
  .mdc-icon-button {
    position: absolute;
    top: -6px;
    right: 0;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.26);
}

.cdkb {
  background: rgba(0, 0, 0, 0.5);
}

.img-fill {
  position: relative;
  height: 100%;

  img {
    object-fit: contain;
  }
}
