@use '@angular/material' as mat;
@use 'sass:map';

// Define a light theme
$light-primary: mat.define-palette(mat.$green-palette, 600, 200, 900);
$light-accent: mat.define-palette(mat.$amber-palette);
$retail-light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
  )
));

// Define a dark theme
$dark-primary: mat.define-palette(mat.$green-palette, 600, 200, 900);
$dark-accent: mat.define-palette(mat.$amber-palette);
$retail-dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
  )
));

// Define main colors
$app-color-primary: map.get(mat.$green-palette, 600);
$app-color-accent: map.get(mat.$amber-palette, 600);
$app-color-warn: map.get(mat.$red-palette, 500);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
